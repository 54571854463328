import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAPICredits } from '../../../utils/api';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './Heading.scss';
import { socket, USER_API_CREDITS } from '../../../constants/socket';
import { rubberBand } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { getAppUser } from '../../../utils/helpers';

const Heading = ({ text }) => {
  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const [credits, setCredits] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const styles = {
    rubberBand: {
      animation: 'x 1s',
      animationName: Radium.keyframes(rubberBand, 'rubberBand'),
    },
  };
  const getCredits = () => {
    setIsLoading(true);
    getAPICredits()
      .then(({ data }) => {
        setCredits(data?.credits ?? 0);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toast.error('Failed to fetch API credits.');
      });
  };

  useEffect(() => {
    getCredits();
  }, []);
  const readAPICredits = ({ userId, apiCredits }) => {
    if (getAppUser()?.userId === userId) {
      setIsAnimating(true);
      setCredits(apiCredits);
    }
  };
  useEffect(() => {
    socket.on(USER_API_CREDITS, readAPICredits);
    return () => socket.off(USER_API_CREDITS);
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      Grouper credits. Contact support to get fueled up if low.
    </Tooltip>
  );

  return (
    <div className='heading'>
      <div className='heading-text'>{text}</div>
      <div className='d-flex ai-center'>
        {!isAdmin && (
          <>
            {!isLoading ? (
              <OverlayTrigger
                placement='left'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                {isAnimating ? (
                  <StyleRoot>
                    <div
                      className='user-api-container'
                      style={styles.rubberBand}
                    >
                      <div className='user-api-credits'>
                        {credits} {credits > 1 ? 'GCs' : 'GC'}
                      </div>
                    </div>
                  </StyleRoot>
                ) : (
                  <div className='user-api-container'>
                    <div className='user-api-credits'>
                      {credits} {credits > 1 ? 'GCs' : 'GC'}
                    </div>
                  </div>
                )}
              </OverlayTrigger>
            ) : (
              <Spinner
                className='credits-spinner'
                animation='border'
                variant='secondary'
                size='sm'
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Heading;
