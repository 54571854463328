import React, { useCallback, useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { JOB_PROGRESS_FROM_NODE, socket } from '../../../constants/socket';
import './TopLoadingBar.scss';
const TopLoadingBar = ({ id, status }) => {
  const [progressValue, setProgressValue] = useState(2);

  const updateProgress = useCallback(
    ({ jobId, progress }) => {
      if (jobId === id) {
        let currProgress = parseInt(progress);
        if (currProgress >= 100) currProgress = 99.9;
        setProgressValue(currProgress);
      }
    },
    [id]
  );
  useEffect(() => {
    socket.on(JOB_PROGRESS_FROM_NODE, updateProgress);
    return () => {
      socket.off(JOB_PROGRESS_FROM_NODE);
    };
  }, [updateProgress]);

  useEffect(() => {
    if (status && status !== 'running') setProgressValue(100);
  }, [status]);

  return (
    <div className='top-loading-bar-container'>
      <LoadingBar
        color='#4e73f8'
        progress={progressValue}
        onLoaderFinished={() => setProgressValue(100)}
      />
    </div>
  );
};

export default TopLoadingBar;
