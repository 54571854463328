import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { getJob, getJobLogs } from '../../../utils/api';
import Loading from '../Loading/Loading';
import DarkModeToggle from 'react-dark-mode-toggle';
import {
  JOB_LOGS_BY_NODE,
  NODE_JOB_STATUS,
  socket,
} from '../../../constants/socket';
import TopLoadingBar from '../TopLoadingBar/TopLoadingBar';
import PulsatingDot from '../PulsatingDot/PulsatingDot';
import { useDispatch } from 'react-redux';
import { setJobType } from '../../../redux/actions/job-details';
import { ADMIN_CONSOLE_PATH } from '../../../constants/paths';
import { setSelectedAdminScreen } from '../../../redux/actions/sidebar';

const JobLogs = () => {
  const dispatch = useDispatch();
  const [didLogsLoad, setDidLogsLoad] = useState(false);
  const [jobLogs, setJobLogs] = useState([]);
  const [jobTitle, setJobTitle] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [jobStatus, setJobStatus] = useState('');

  const history = useHistory();
  const params = useParams();
  const id = params?.jobId;
  const type = params?.jobType;
  const state = params?.jobState;
  const userId = params?.userId;

  const appendLog = useCallback(
    ({ jobId, log }) => {
      if (jobId === id) {
        if (!didLogsLoad) setDidLogsLoad(true);
        const div = document.getElementById('logs-div');
        const childDiv = document.createElement('div');
        childDiv.appendChild(document.createTextNode(log));
        div.appendChild(childDiv);
        div.scrollIntoView({
          block: 'end',
        });
      }
    },
    [id, didLogsLoad]
  );
  useEffect(() => {
    socket.on(JOB_LOGS_BY_NODE, appendLog);
    return () => socket.off(JOB_LOGS_BY_NODE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appendLog]);
  useEffect(() => {
    getJobLogs(id).then(({ data: { logs, report_title, status } }) => {
      setJobTitle(report_title);
      setJobLogs(logs);
      setDidLogsLoad(logs?.length > 0 || status !== 'running');
    });
  }, [id, type]);

  useEffect(() => {
    getJob(id).then(({ data }) => {
      setJobStatus(data?.status);
    });
  }, [id, type]);

  const updateStatus = useCallback(
    ({ jobId, status }) => {
      if (id === jobId) {
        setJobStatus(status);
      }
    },
    [id]
  );

  useEffect(() => {
    socket.on(NODE_JOB_STATUS, updateStatus);
    return () => socket.off(NODE_JOB_STATUS);
  }, [updateStatus]);

  useEffect(() => {
    if (userId) {
      dispatch(setJobType(`${ADMIN_CONSOLE_PATH}/users`));
      dispatch(setSelectedAdminScreen('users'));
    } else {
      dispatch(setJobType(`/jobs/${state}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className='breadcrumb-container'>
        <span
          onClick={() => history.go(-2)}
          className='cursor-pointer breadcrumb-link'
        >
          {userId
            ? 'All Jobs'
            : state === 'active'
            ? 'Active Jobs'
            : 'Archived Jobs'}
        </span>
        <span className='breadcrumb-separator'>{'>'}</span>
        <span
          onClick={() => history.goBack()}
          className='cursor-pointer breadcrumb-link'
        >
          {jobTitle}
        </span>
        <span className='breadcrumb-separator'>{'>'}</span>Logs
      </div>
      <div className='job-logs'>
        <div className='logs-header'>
          <div className='logs-header-status-container'>
            <span>Status: </span>
            <PulsatingDot status={jobStatus} />
            <span>{jobStatus ?? 'Loading'}</span>
          </div>
          <div className='switch-button-container'>
            <span className='mode-text'>
              {!isDarkMode ? 'Light' : 'Dark'} Mode
            </span>
            <DarkModeToggle
              onChange={setIsDarkMode}
              checked={isDarkMode}
              size={50}
              speed={5}
            />
          </div>
        </div>
        <div
          className={`logs-container ${
            isDarkMode ? 'dark-mode' : 'light-mode'
          }`}
        >
          <TopLoadingBar id={id} status={jobStatus} />
          {didLogsLoad ? (
            <div id='logs-div'>
              {jobLogs?.map((jobLog, index) => (
                <div key={index}>{jobLog}</div>
              ))}
            </div>
          ) : (
            <div className='logs-loader-container'>
              <Loading whichLoader='logs' />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default JobLogs;
