import './App.scss';
import './components/Common/Style.scss';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import './components/Common/FloatingLabel.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import Toast from './components/Common/Toast';
import Router from './Router';
import { ACTIVE_JOBS_PATH } from './constants/paths';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAdmin, setIsUserLoggedIn } from './redux/actions/auth';
import { areYouAdmin } from './utils/api';
import { toast } from 'react-toastify';
import { getAppUser } from './utils/helpers';
import JobWaitingLoader from './components/Common/JobWaitingLoader/JobWaitingLoader';

const App = () => {
  const sendingFileProgress = useSelector(
    (state) => state?.jobFields?.sendingFileProgress
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  useEffect(() => {
    const grouperAppUser = getAppUser();
    if (grouperAppUser) {
      dispatch(setIsUserLoggedIn(true));
      history.push(
        window.location.pathname === '/login' ||
          window.location.pathname === '/'
          ? ACTIVE_JOBS_PATH
          : window.location.pathname
      );
    } else if (!token) {
      history.push('/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    areYouAdmin()
      .then(({ data }) => dispatch(setIsAdmin(data)))
      .catch(({ data }) => toast.error(data?.message));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='main'>
      {sendingFileProgress && <JobWaitingLoader />}
      <Toast />
      <Router />
    </div>
  );
};

export default App;
