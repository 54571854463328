import { io } from 'socket.io-client';
import { API_SOCKET_URL } from './environment-variables';
export const socket = io(API_SOCKET_URL, { transports: ['websocket'] });

export const NODE_JOB_STATUS = 'NODE_JOB_STATUS';
export const INPUT_FILE_PATH = 'INPUT_FILE_PATH';
export const JOB_LOGS_BY_NODE = 'JOB_LOGS_BY_NODE';
export const USER_API_CREDITS = 'USER_API_CREDITS';
export const JOB_PROGRESS_FROM_NODE = 'JOB_PROGRESS_FROM_NODE';
export const INPUT_SIZE_FROM_NODE = 'INPUT_SIZE_FROM_NODE';
