import {
  SET_COUNTRY,
  SET_DOCUMENT_FILE,
  SET_REPORT_TITLE,
  SET_LANGAUGE,
  SET_LOCATION,
  SET_LOCATION_OPTIONS,
  SET_SEARCH_ENGINE,
  SET_TARGET_DOMAIN,
  SET_IGNORE_SPECIAL_CHARACTERS,
  SET_IS_START_JOB_BUTTON_CLICKED,
  SET_GROUPING_METHOD,
  SET_MAIN_KEYWORD_GROUPING_ACCURACY,
  SET_VARIANT_KEYWORD_GROUPING_ACCURACY,
  SET_POSITION_THRESHOLD,
  SET_DEFAULT_JOB_VALUES,
  SET_VARIANT_POSITION_THRESHOLD,
  SET_NO_OF_CLUSTERS,
  SET_SENDING_FILE_PROGRESS,
} from '../action-types/job-fields';

const initialState = {
  reportTitle: '',
  targetDomain: '',
  searchEngine: 'Google',
  country: 'United States',
  location: 'None',
  locationOptions: [],
  language: 'English',
  documentFile: '',
  groupingMethod: 'Main Keyword Only',
  noOfClusters: 0,
  mainKeywordGroupingAccuracy: 3,
  variantKeywordGroupingAccuracy: 6,
  positionThreshold: 10,
  variantPositionThreshold: 10,
  ignoreSpecialCharacters: true,
  isStartJobButtonClicked: false,
  sendingFileProgress: null,
};
export const jobFieldsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_REPORT_TITLE: {
      return {
        ...state,
        reportTitle: payload,
      };
    }

    case SET_TARGET_DOMAIN: {
      return {
        ...state,
        targetDomain: payload,
      };
    }

    case SET_SEARCH_ENGINE: {
      return {
        ...state,
        searchEngine: payload,
      };
    }

    case SET_COUNTRY: {
      return {
        ...state,
        country: payload,
      };
    }

    case SET_LOCATION: {
      console.log('SET_LOCATION', payload);
      return {
        ...state,
        location: payload,
      };
    }

    case SET_LANGAUGE: {
      return {
        ...state,
        language: payload,
      };
    }

    case SET_LOCATION_OPTIONS: {
      return {
        ...state,
        locationOptions: payload,
      };
    }

    case SET_DOCUMENT_FILE: {
      return {
        ...state,
        documentFile: payload,
      };
    }

    case SET_IGNORE_SPECIAL_CHARACTERS: {
      return {
        ...state,
        ignoreSpecialCharacters: payload,
      };
    }

    case SET_IS_START_JOB_BUTTON_CLICKED: {
      return {
        ...state,
        isStartJobButtonClicked: payload,
      };
    }

    case SET_GROUPING_METHOD: {
      return {
        ...state,
        groupingMethod: payload,
      };
    }

    case SET_MAIN_KEYWORD_GROUPING_ACCURACY: {
      return {
        ...state,
        mainKeywordGroupingAccuracy: payload,
      };
    }

    case SET_VARIANT_KEYWORD_GROUPING_ACCURACY: {
      return {
        ...state,
        variantKeywordGroupingAccuracy: payload,
      };
    }

    case SET_POSITION_THRESHOLD: {
      return {
        ...state,
        positionThreshold: payload,
      };
    }

    case SET_VARIANT_POSITION_THRESHOLD: {
      return {
        ...state,
        variantPositionThreshold: payload,
      };
    }

    case SET_DEFAULT_JOB_VALUES: {
      return initialState;
    }

    case SET_NO_OF_CLUSTERS: {
      return {
        ...state,
        noOfClusters: payload,
      };
    }
    case SET_SENDING_FILE_PROGRESS: {
      return {
        ...state,
        sendingFileProgress: payload,
      };
    }
    default:
      return state;
  }
};
