import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cameraIcon from '../../../assets/img/sidebar-icons/camera-icon.svg';
import { setIsUserAvatarHovered } from '../../../redux/actions/users';
import { isValidFormat } from '../../../utils/helpers';
import './AvatarEditor.scss';
import ImageCropper from './ImageCropper';

const AvatarEditor = () => {
  const [isCropping, setIsCropping] = useState(false);
  const [upImg, setUpImg] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [hasImageLoaded, setHasImageLoaded] = useState(false);

  const isUserAvatarHovered = useSelector(
    (state) => state?.users?.isUserAvatarHovered
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    setSelectedImage(null);
    setIsCropping(false);
  };
  return (
    <>
      <ImageCropper
        selectedImage={selectedImage}
        upImg={upImg}
        setUpImg={setUpImg}
        isCropping={isCropping}
        handleClose={handleClose}
        setSelectedImage={setSelectedImage}
        hasImageLoaded={hasImageLoaded}
      />
      <label
        className={`${
          !isUserAvatarHovered ? 'disable-editing' : ''
        } custom-file-upload`}
        onMouseLeave={() => dispatch(setIsUserAvatarHovered(false))}
      >
        <img src={cameraIcon} alt='camera' />
        <input
          type='file'
          accept='.png, .jpg, .jpeg'
          value={setSelectedImage}
          onChange={(e) => {
            if (isValidFormat(e?.target?.files?.[0]?.type)) {
              setSelectedImage(e?.target?.files?.[0]);
              setIsCropping(true);
              const reader = new FileReader();
              reader.addEventListener('loadstart', () =>
                setHasImageLoaded(false)
              );
              reader.addEventListener('loadend', () => setHasImageLoaded(true));
              reader.addEventListener('load', () => setUpImg(reader.result));
              reader.readAsDataURL(e?.target?.files?.[0]);
            }
          }}
        />
      </label>
    </>
  );
};

export default AvatarEditor;
